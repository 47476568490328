import React, { useState } from 'react';

import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import UpdateIcon from '@material-ui/icons/Update';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import CompareIcon from '@material-ui/icons/Compare';
import TuneIcon from '@material-ui/icons/Tune';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Typist from 'react-typist';

const shuffle = array => {
  for(let i = (array.length - 1); i > 0; i--){
    const j = Math.floor(Math.random() * i)
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array;
}

const shuffled = shuffle([
  'tweet',
  'gram',
  'hire',
  'are down',
  'get mentioned in the news',
  'update their website',
  'run ads',
  'are reviewed on glassdoor',
  'stock goes up',
  'release new products'
]);

function App() {
  const mobile = useMediaQuery('(max-width:600px)');

  const [typingFoo, setTypingFoo] = useState(0);
  return (
    <div className="App">
        <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
            <div className="container">
                <a className="logo text-uppercase" href="/">
                    <img src="images/logo-test.png" alt="" className="logo-dark" height="50" />
                </a>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="mdi mdi-menu"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                        <li className="nav-item active">
                            <a href="#home" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item">
                            <a href="#features" className="nav-link">Features</a>
                        </li>
                        <li className="nav-item">
                            <a href="#integrations" className="nav-link">Integrations</a>
                        </li>
                        <li className="nav-item">
                            <a href="#pricing" className="nav-link">Plans</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://app.comp.watch/signin" className="nav-link">Sign In</a>
                        </li>
                    </ul>
                    <a href="https://app.comp.watch/signup">
                        <button className="btn btn-success btn-rounded navbar-btn">Sign Up</button>
                    </a>
                </div>
            </div>
        </nav>

        
        <section className="hero-section" id="home">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="hero-wrapper mb-4">
                            <h1
                              className="hero-title mb-4"
                              style={
                                mobile ? {
                                  minHeight: '160px'
                                } : {}
                              }
                            >
                                Know when your competitors
                                <Typist key={typingFoo} avgTypingDelay={85} onTypingDone={() => setTypingFoo(typingFoo+1)}>
                                  {
                                    shuffled.map(thing => {
                                      return (
                                        <span>
                                          <span> {thing}.</span>
                                          <Typist.Backspace count={thing.length + 1} delay={500} />
                                        </span>
                                      )
                                    })
                                  }
                                </Typist>
                            </h1>

                            <p>In today's fast-paced world, it's more important than ever to keep up with your competitors, but between social media, the markets, and the news, there are so many things to monitor. CompWatch gives you easy competitive intel at your fingertips to make sure you can capitalize on every moment.</p>

                            <div className="mt-4">
                                <a href="https://app.comp.watch/signup" className="btn btn-primary mt-2 mr-2">Get Started</a>
                                <a href="#features" className="btn btn-success mt-2 mr-2">Learn more</a>
                            </div>
                        </div>
                        
                    </div>

                    <div className="col-lg-6 col-sm-8">
                        <div className="home-img mt-5 mt-lg-0">
                            <img src="https://a.slack-edge.com/80588/img/api/messaging_landing_managing.png" alt="" className="img-fluid mx-auto d-block" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section" id="features">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="text-center mb-5">
                            <h5 className="text-primary text-uppercase small-title">Features</h5>
                            <h4 className="mb-3">What CompWatch can do for you</h4>
                            <p>CompWatch's easy-to-use interface will have you in the know in no time.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-5">
                        <div>
                            <div className="avatar-md mb-4">
                                <span className="avatar-title rounded-circle bg-soft-primary">
                                    <TuneIcon />
                                </span>
                            </div>
                            <h5>Easily monitor anyone</h5>
                            <p className="mb-4">With a name and a website, you can set up a competitor's profile in a few quick steps.</p>

                            <div className="row">
                                
                            </div>

                            <div className="mt-4">
                                <a href="https://app.comp.watch/signup" className="btn btn-primary">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 ml-lg-auto col-sm-8">
                        <div className="card border border-light shadow-none mt-5 mt-lg-0">
                            <div className="card-header border-0 bg-transparent">
                                <div>
                                    <i className="mdi mdi-circle text-danger mr-1"></i>
                                    <i className="mdi mdi-circle text-warning mr-1"></i>
                                    <i className="mdi mdi-circle text-success mr-1"></i>
                                </div>
                            </div>
                            <div className="card-body bg-light">
                                <div className="box-shadow">
                                    <img src="images/features/comp2.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {
                  mobile ? (
                    <div className="row">
                        <div className="col-lg-5 ml-lg-auto">
                            <div className="mt-4 mt-lg-0">
                                <div className="avatar-md mb-4">
                                    <span className="avatar-title rounded-circle bg-soft-primary">
                                        <NotificationsActiveIcon />
                                    </span>
                                </div>
                                <h5>Choose your integrations</h5>
                                <p className="mb-4">From news alerts to down detection, CompWatch can help you keep track of the areas that matter most for your success.</p>

                                <div className="row">
                                </div>

                                <div className="mt-4">
                                    <a href="https://app.comp.watch/signup" className="btn btn-primary">Get Started</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-8">
                            <div className="card border border-light shadow-none mt-5 mt-lg-0">
                                <div className="card-header border-0 bg-transparent">
                                    <div>
                                        <i className="mdi mdi-circle text-danger mr-1"></i>
                                        <i className="mdi mdi-circle text-warning mr-1"></i>
                                        <i className="mdi mdi-circle text-success mr-1"></i>
                                    </div>
                                </div>
                                <div className="card-body bg-light">
                                    <div className="box-shadow">
                                        <img src="images/features/ints2.png" alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                  ) : (
                    <div className="row">
                        <div className="col-lg-5 col-sm-8">
                            <div className="card border border-light shadow-none">
                                <div className="card-header border-0 bg-transparent">
                                    <div>
                                        <i className="mdi mdi-circle text-danger mr-1"></i>
                                        <i className="mdi mdi-circle text-warning mr-1"></i>
                                        <i className="mdi mdi-circle text-success mr-1"></i>
                                    </div>
                                </div>
                                <div className="card-body bg-light">
                                    <div className="box-shadow">
                                        <img src="images/features/ints2.png" alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 ml-lg-auto">
                            <div className="mt-4 mt-lg-0">
                                <div className="avatar-md mb-4">
                                    <span className="avatar-title rounded-circle bg-soft-primary">
                                        <NotificationsActiveIcon />
                                    </span>
                                </div>
                                <h5>Choose your integrations</h5>
                                <p className="mb-4">From news alerts to down detection, CompWatch can help you keep track of the areas that matter most for your success.</p>

                                <div className="row">
                                </div>

                                <div className="mt-4">
                                    <a href="https://app.comp.watch/signup" className="btn btn-primary">Get Started</a>
                                </div>
                            </div>
                        </div>

                    </div>
                  )
                }
                

                <div className="row ">
                    <div className="col-lg-5">
                        <div>
                            <div className="avatar-md mb-4">
                                <span className="avatar-title rounded-circle bg-soft-primary">
                                    <TuneIcon />
                                </span>
                            </div>
                            <h5>Make customizable alerts that work for you</h5>
                            <p className="mb-4">Set parameters and cadences for alerts that work on your time and get messages through your preferred communication method.</p>

                            <div className="row">
                            </div>

                            <div className="mt-4">
                                <a href="https://app.comp.watch/signup" className="btn btn-primary">Get Started</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 ml-lg-auto col-sm-8">
                        <div className="card border border-light shadow-none mt-5 mt-lg-0">
                            <div className="card-header border-0 bg-transparent">
                                <div>
                                    <i className="mdi mdi-circle text-danger mr-1"></i>
                                    <i className="mdi mdi-circle text-warning mr-1"></i>
                                    <i className="mdi mdi-circle text-success mr-1"></i>
                                </div>
                            </div>
                            <div className="card-body bg-light">
                                <div className="box-shadow">
                                    <img src="images/features/slack.png" alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section bg-light" id="integrations">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="text-center mb-5">
                            <h5 className="text-primary text-uppercase small-title">Integrations</h5>
                            <h4 className="mb-3">Integrations We Provide</h4>
                            <p>Keep track of your competitors using our integrations.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-sm-6">
                        <div className="text-center p-4 mt-3">
                            <div className="avatar-md mx-auto mb-4">
                                <span className="avatar-title rounded-circle bg-soft-primary">
                                    <TwitterIcon />
                                </span>
                            </div>
                            <h5 className="font-18">Twitter</h5>
                            <p className="mb-0">Know when your competitor's have high engagement.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="text-center p-4 mt-3">
                            <div className="avatar-md mx-auto mb-4">
                                <span className="avatar-title rounded-circle bg-soft-primary">
                                    <InstagramIcon />
                                </span>
                            </div>
                            <h5 className="font-18">Instagram</h5>
                            <p className="mb-0">Follow your competitor's posts.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="text-center p-4 mt-3">
                            <div className="avatar-md mx-auto mb-4">
                                <span className="avatar-title rounded-circle bg-soft-primary">
                                    <UpdateIcon />
                                </span>
                            </div>
                            <h5 className="font-18">Uptime</h5>
                            <p className="mb-0">Know when your competitors are down.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="text-center p-4 mt-3">
                            <div className="avatar-md mx-auto mb-4">
                                <span className="avatar-title rounded-circle bg-soft-primary">
                                    <ShowChartIcon />
                                </span>
                            </div>
                            <h5 className="font-18">Stock</h5>
                            <p className="mb-0">Know when your competitor's stock moves.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="text-center p-4 mt-3">
                            <div className="avatar-md mx-auto mb-4">
                                <span className="avatar-title rounded-circle bg-soft-primary">
                                  <AnnouncementIcon />
                                </span>
                            </div>
                            <h5 className="font-18">News</h5>
                            <p className="mb-0">Keep track of your competitor's press.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="text-center p-4 mt-3">
                            <div className="avatar-md mx-auto mb-4">
                                <span className="avatar-title rounded-circle bg-soft-primary">
                                    <CompareIcon />
                                </span>
                            </div>
                            <h5 className="font-18">Web Diff</h5>
                            <p className="mb-0">Know when your competitor changes their website.</p>
                        </div>
                    </div>
                </div>
{
                // <div className="row mt-4">
                    // <div className="col-lg-12">
                    //     <div className="text-center">
                    //         <a href="/" className="btn btn-success">View more</a>
                    //     </div>
                    // </div>
                // </div>
}
            </div>
        </section>

        <section className="section bg-light" id="pricing">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="text-center mb-5">
                            <h5 className="text-primary text-uppercase small-title">Pricing</h5>
                            <h4 className="mb-3">Choose your Plan</h4>
                            <p>It will be as simple as occidental in fact, it will be Occidental.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3">
                        <div className="pricing-plan card text-center">
                            <div className="card-body p-4">
                                <h5 className="mt-2 mb-5">Free</h5>

                                <h1 className="mb-5"><sup><small>$</small></sup>0/ <span className="font-16">Mo</span></h1>

                                <div>
                                    <a href="https://app.comp.watch/signup" className="btn btn-primary">Join now</a>
                                </div>
                                <div className="plan-features mt-5">
                                    <p>Competitors: <span className="text-success">1</span></p>
                                    <p>Frequency: <span className="text-success">1 hour</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="pricing-plan card text-center">
                            <div className="card-body p-4">
                                <h5 className="mt-2 mb-5">Basic</h5>

                                <h1 className="mb-5"><sup><small>$</small></sup>15/ <span className="font-16">Mo</span></h1>

                                <div>
                                    <a href="https://app.comp.watch/signup" className="btn btn-primary">Join now</a>
                                </div>
                                <div className="plan-features mt-5">
                                    <p>Competitors: <span className="text-success">5</span></p>
                                    <p>Frequency: <span className="text-success">30 minutes</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="pricing-plan card text-center">
                            <div className="card-body p-4">
                                <h5 className="mt-2 mb-5">Pro</h5>

                                <h1 className="mb-5"><sup><small>$</small></sup>40/ <span className="font-16">Mo</span></h1>

                                <div>
                                    <a href="https://app.comp.watch/signup" className="btn btn-primary">Join now</a>
                                </div>
                                <div className="plan-features mt-5">
                                    <p>Competitors: <span className="text-success">20</span></p>
                                    <p>Frequency: <span className="text-success">15 minutes</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="pricing-plan card text-center">
                            <div className="card-body p-4">
                                <h5 className="mt-2 mb-5">Business</h5>

                                <h1 className="mb-5"><sup><small>$</small></sup>99/ <span className="font-16">Mo</span></h1>

                                <div>
                                    <a href="https://app.comp.watch/signup" className="btn btn-primary">Join now</a>
                                </div>
                                <div className="plan-features mt-5">
                                    <p>Competitors: <span className="text-success">Unlimited</span></p>
                                    <p>Frequency: <span className="text-success">5 minutes</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-primary py-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="float-sm-left">
                            <a href="/">
                                <img src="images/logo-test.png" alt="" height="20" />
                            </a>
                        </div>
                        <div className="float-sm-right mt-4 mt-sm-0">
                            <p className="copyright-desc text-white mb-0">2020 © CompWatch</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  );
}

export default App;
